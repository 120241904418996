<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>Sell Your Restaurant Business</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
                            <div class="alert alert-success" role="alert" v-if="successMessage">
                                {{ successMessage }}
                            </div>
							<div class="form-group row">
								<label for="inputTitle" class="col-sm-2 col-form-label">Title <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.title" @blur="validationRule()"
										class="form-control" id="inputTitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

                            <div class="form-group row">
								<label for="inputDescription" class="col-sm-2 col-form-label">Description <span class="text-danger">*</span></label>
								<div class="col-sm-10">
                                    <textarea class="form-control" id="inputDescription" v-model="dataRequest.description" @blur="validationRule()"></textarea>
									<small class="text-danger">{{validationData.description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row" >
								<div class="form-group row" >
									<label for="inputImage" class="col-sm-2 col-form-label">Video</label>
									<div class="col-sm-10">
										<input type="file" class="form-control" id="inputImage" @change="readImage">
										<img v-if="dataRequest.image" v-bind:src="image" class="banner-image mt-2"/>
										<small class="text-danger">{{validationData.image}}</small>
									</div>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'sellHospitality',
		metaInfo: {
            title: "Services",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				image: null,
                successMessage: "",
				dataRequest: {
					id: "",
					title: "",
                    description: "",
					image: ""
				},
				validationData: {
					title: "",
                    description: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				sellHospitalityUpdateAction: 'sellHospitalityUpdateAction',
				sellHospitalityDetailAction: 'sellHospitalityDetailAction'
			}),
			readImage(event) {
				this.dataRequest.image = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image = e.target.result
					this.validationRule()
				};
			},
			async getFields(){
				let option = {}
				await this.sellHospitalityDetailAction({
					...option
				}).then(res => {
					this.currentImage = res.data.image
                    this.dataRequest.id = res.data.id
					this.dataRequest.title = res.data.title
					this.dataRequest.description = res.data.description
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('id', this.dataRequest.id);
				formData.append('title', this.dataRequest.title); 
                formData.append('description', this.dataRequest.description); 
				formData.append('video', this.dataRequest.image); 

				await this.sellHospitalityUpdateAction(
					formData
				).then(res => {
                    this.successMessage = "Data store is success!";
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.dataRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}

                if (this.dataRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>